import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from '@angular/common/http';
import { ScriptLoadService } from '../modules/shared/services/script-load.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,private _ac:ActivatedRoute,private http:HttpClient,private sl:ScriptLoadService) { }

 async loggedIn() {
      let url = new URL(window.location.href);
      const id_token = url.searchParams.get('id_token');
      if(id_token){
        localStorage.setItem('access_token',id_token)
        localStorage.setItem('GoogleLoginState','true')
       let response = await this.loginFunc({token:id_token},'decode-token');
       return response;
      }
      else{
        if (localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails') || '').token) {
          // console.log('Token present');
        }
        else {
          console.log('token unavailable');
          this.router.navigate(['/auth']);
        }
        return localStorage.getItem('userDetails') ? !!JSON.parse(localStorage.getItem('userDetails') || '').token : false;
      }
  }
  async loginFunc(data: any, endpoint: string) {
    return await new Promise((resolve,reject)=>{
       this.http.post(`https://www.monetlive.com/many/api/${endpoint}`, data).subscribe((next: any) => {
          if (!next.error) {
            localStorage.setItem('userDetails', JSON.stringify(next.user));
            if (next && next?.user?.plan) {
              this.http.get(`https://www.monetlive.com/many/api/getPlan?planId=${next.user?.plan.id}`).subscribe((res: any) => {
                localStorage.setItem('userPlanDetails', JSON.stringify(res?.plan));
              });
            }
            else {
              console.warn('Plan Object missing in response');
            }
             this.sl.load('janus').then((user: any) => this.sl.load('user').then((janus: any) => resolve(user[0].loaded && janus[0].loaded)))

          } else {
            reject();
          }
        });
      })
  }
}


@Injectable({
  providedIn: 'root'
})
export class IsLoggedIn {
  constructor(
    private router: Router, private snackBar: MatSnackBar, private au: AuthService) {
  }
  resolve(): any {
    if (localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails' || '{}') || '') && JSON.parse(localStorage.getItem('userDetails' || '{}') || '').token) {
      if (!((location.href.search('/report/') !== -1 || location.href.search('/teacher/') !== -1))) {
        this.router.navigate(['profile/dashboard/']);
      }
    }
    else {
      console.log('Token not found');
      this.snackBar.open('User Not Logged IN', '', {
        duration: 3000,
        horizontalPosition: 'left',
        verticalPosition: 'bottom',
        panelClass: ['custom-snackbar']
      });
      this.router.navigate(['/auth']);
    }
    return !!JSON.parse(localStorage.getItem('userDetails') || '').token;
  }
}
