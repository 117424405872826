import {Injectable} from '@angular/core';
import { BehaviourSubjectsService } from 'src/app/services/behaviour-subjects.service';

declare function io(url: string, extra?: any): any;

declare let socket: any;

declare let dynamoLink: any;

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  socket: any;

  constructor(private bhvSub:BehaviourSubjectsService) {
  }

  generateSocket(): any {
    try {
      if (!socket && dynamoLink) {
        this.bhvSub.obDynamicLink(`${dynamoLink}`);
        this.socket = io(`https://www.monetlive.com`, {
          path: `/${dynamoLink}/sock`,
          transports: ['websocket'],
          reconnect: true
        });
      } else {
        if(socket){
        this.bhvSub.obDynamicLink(`${dynamoLink}`);
          this.socket = socket;
        }
        else{
          setTimeout(() => {
            this.generateSocket();
          }, 2000);
        }
      }
    } catch (e) {
      if(dynamoLink){
        this.bhvSub.obDynamicLink(`${dynamoLink}`);
        this.socket = io(`https://www.monetlive.com`, {
          path: `/${dynamoLink}/sock`,
          transports: ['websocket'],
          reconnect: true
        });
      }
      else{
        setTimeout(() => {
          this.generateSocket();
        }, 2000);
      }
    }
    if(this.socket){
      this.socket.on('disconnect', (e: any) => {
        console.log('socket disconnect', e);
      });
    }
  }

  disconnectSocket(): void {
    this.socket.disconnect();
  }

  managerSocket(grp: string): void {
    this.socket = io(`https://www.monetlive.com`, {path: `/${grp}/sock`, transports: ['websocket'], reconnect: true});
  }

}
